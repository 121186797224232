<template>
	<div class="page">
		<div class="page-item">
			<div class="page-item-left">
				<div class="page-item-left-head">
					<img src="@/assets/arrowright.png" />
					<span>学习培训</span>
				</div>
				<div class="page-item-left-content">
					系统收录全套物业学习培训资料，并提供定制化培训服务；降本增效，让物业培训不再困难。
				</div>
				<img src="@/assets/pxfw.png" />
			</div>
			<div class="page-item-right">
				<img src="@/assets/xxpx.png" />
			</div>
		</div>
		<div class="page-item">
			<div class="page-item-left">
				<div class="page-item-left-head">
					<img src="@/assets/arrowright.png" />
					<span>上下班打卡</span>
				</div>
				<div class="page-item-left-list">
					<img src="@/assets/xxpx4.png" />
					<img src="@/assets/xxpx5.png" />
				</div>
			</div>
			<div class="page-item-right">
				<div class="page-item-right-yjhjgj" style="width: 60%;">
					<div class="page-item-right-yjhjgj-title">
						人员考勤管理，一键上下班打卡
					</div>
					<div class="page-item-right-yjhjgj-content">
						U+接入腾讯地图的第三方地图服务以及中国三大运营商的电信服务，员工上下班可通过定位自身位置或连接公司电信网络，完成上下班打卡操作，系统将统一录入考核系统，并支持表格导出导入。
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ExploreU'
	}
</script>

<style scoped lang="scss">
	.page {
		min-width: 1200px;

		.page-item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 1200px;
			height: 80vh;
			min-height: 900px;
			margin: 0 auto;

			.page-item-left {
				flex: 1;

				.page-item-left-head {
					display: flex;
					flex-direction: column;

					img {
						width: 40px;
					}

					span {
						margin-top: 10px;
						font-size: 30px;
					}
				}

				.page-item-left-content {
					margin-top: 10px;
					font-size: 20px;
				}

				>img {
					width: 100%;
					margin-top: 20px;
				}

				.page-item-left-list {
					margin-top: 20px;

					img {
						width: 45%;
					}

					img:last-child {
						margin-left: 20px;
					}
				}

				.page-item-left-bmfw {
					display: flex;
					flex-direction: column;
					width: 55%;

					.page-item-left-bmfw-title {
						margin-top: 40px;
						font-size: 25px;
						color: rgba(62, 106, 175, 1);
					}

					.page-item-left-bmfw-content {
						margin-top: 30px;
						font-size: 20px;
					}
				}
			}

			.page-item-right {
				display: flex;
				justify-content: flex-end;
				flex: 1;

				>img {
					width: 45%;
				}

				.page-item-right-yjhjgj {
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 55%;
					margin-top: 50px;

					>img {
						width: 80%;
					}

					.page-item-right-yjhjgj-title {
						margin-top: 40px;
						font-size: 25px;
						color: rgba(62, 106, 175, 1);
					}

					.page-item-right-yjhjgj-content {
						margin-top: 30px;
						font-size: 20px;
					}
				}

				.page-item-right-list {

					img {
						width: 45%;
					}

					img:last-child {
						margin-left: 20px;
					}
				}
			}
		}

		.page-item:first-child {
			margin-top: 0;
		}
	}
</style>